<template>
  <div>
    <slot />
  </div>
</template>
<style lang="scss">
body {
  margin: 0;
  background: black;
}
</style>
