import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Vue3Storage from "vue3-storage";

/* グローバルで読み取るcssの定義 */
import "@/assets/css/variable.scss";

createApp(App)
  .use(router)
  .use(Vue3Storage, { namespace: "adbox_" })
  .mount("#app");
